import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

const CloseButton = () => (
  <Link css={css`
    position: sticky;
    float: right;
    top: 1rem;
    color: #4B4129;
    z-index: 100;

    .icon {
      width: 2.5rem;
      height: 2.5rem;
    }

    @media (min-width: 60rem) {
      top: 0;
    }
  `} to="/">
    <svg className="icon">
      <use xlinkHref="#icon-close" />
    </svg>
  </Link>
)

export default CloseButton;