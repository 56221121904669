import React from "react"
import { Link, graphql } from "gatsby"
import { css } from "@emotion/core"
import SEO from "../components/seo"
import CloseButton from "../components/close-button"

export default ({ data }) => {
  const posts = data.allMarkdownRemark.edges

  return (
    <>
      <SEO title="Aktuelles" />
      <CloseButton />
      <h1 className="page-title">Aktuelles</h1>
      {posts.map(({ node }) => (
        <div key={node.id} css={css`
          & + & {
            margin-top: 2rem;
            padding-top: 2rem;
            border-top: 2px solid #8a784c;
          }
        `}>
          <Link
            to={node.fields.slug}
            style={{
              textDecoration: "none",
              color: "inherit"
            }}
          >
            <h2 css={css`margin: 0;`}>
              {node.frontmatter.title}{" "}
              <div css={css`
                color: #b18725;
                font-size: 80%;
              `}>{node.frontmatter.date}</div>
            </h2>
          </Link>
          <div dangerouslySetInnerHTML={{ __html: node.html }} />
        </div>
      ))}
    </>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "\/aktuelles/" } },
      sort: { fields: [frontmatter___date], order: DESC },
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "D. MMMM YYYY", locale: "de")
          }
          fields {
            slug
          }
          html
        }
      }
    }
  }
`